<template>
  <page-layout title="Swap Classes" :linkDefault="`/${this.username}/profile/edit`">
    <swap-classes :course="this.course"/>
  </page-layout>
</template>

<script >
import PageLayout from "../components/base/pageLayout.vue";
import SwapClasses from '../components/swapClasses.vue';
import { useRoute } from 'vue-router'
import { mapState } from 'vuex'

export default {
  components: { PageLayout, SwapClasses },
  name: "Swap",
  created() {
    if(this.$route.query.class){
      this.course = this.$store.state.user.classes.filter((course) => course.desc === this.$route.query.class)[0]
}  },
  data() {
    return {
      course: null
    }
  },
  computed: {
    ...mapState(['username']),
  },
  setup() {
    const route = useRoute();
    const { desc } = route.params;
    return { desc };
  },
};
</script>