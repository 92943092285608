<template>
  <div class="relative h-full">
    <div class="h-52 my-4">
    <div class="inline-flex">
      <p class="text-3xl font-bold p-4 pr-2">swap</p>
      <div class="mt-6 ml-1 w-52">
        <!-- <search-dropdown :options="courses.filter((course) => course.desc !== Class.desc)" @on-item-selected="Class = $event;" @on-item-reset="Class = null" placeholder="search class to swap" /> -->
      </div>
    </div>

    <course
      class="mx-auto"
        v-if="Class"
        :name="Class.name"
        :desc="Class.desc"
        :id="Class._id"
        :edit="false"
      />
    </div>
    <div class="h-52 my-4">

      <div class="inline-flex my-4">
        <p class="text-3xl font-bold p-4 pr-5">with</p>
        <div class="mt-6 ml-1 w-52">
          <!-- <search-dropdown :options="courses" @on-item-selected="selected = $event;" @on-item-reset="selected = null" placeholder="search classes by name or abbreviation" />           -->
        </div>
      </div>
      <course
      class="mx-auto"
      v-if="selected"
        :name="selected.name"
        :desc="selected.desc"
        :id="selected._id"
        :edit="false"
      />
    </div>

    <button
    v-if="selected && Class"
      class="
        my-24
        mx-4
        float-right
        z-50
        rounded-full
        py-2
        px-8
        bg-orange
        text-white
        font-bold
        shadow-lg
        hover:shadow
      "
      @click="
        this.$router.push(`/${username}/profile/edit`); 
        this.enroll({course: this.selected});
        this.remove_class(this.Class._id)
      "
    >
      confirm
    </button>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { mapActions, mapState } from 'vuex';
import Course from './edits/class.vue'
// import SearchDropdown from './searchDropdown.vue';
export default {
  props: {
    course: Object,
  },
  components: {
    Course,
    SearchDropdown,
  },
  methods: {
    ...mapActions(['enroll', 'remove_class'])
  },
  data() {
    return {
      select: "select class",
      showModal: false,
      Class: this.course,
      selected: null,
      items: this.$store.state.user.university.classes.filter((one_class) => one_class.desc !== this.course.desc),
    };
  },
  computed: {
    ...mapState(['filler_img', 'username', 'user']),
    courses () {
      let courses = [];
      for(let i = 0; i < this.user.university.classes.length; i++)
        courses.push({_id: this.user.university.classes[i]._id, name: this.user.university.classes[i].desc + " - " + this.user.university.classes[i].name, desc: this.user.university.classes[i].desc });
      return courses;
    }
  },
  setup() {
    const { router } = useRouter
    return { router }
  }
};
</script>